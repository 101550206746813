import * as tslib_1 from "tslib";
import { Events } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { first, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LogglyLoggerService } from '../loggly-logger/loggly-logger.service';
import { ConfigService } from '../config/config.service';
import { SharedService } from '../shared/shared.service';
import { Storage } from '@ionic/storage';
import { convertSnaps } from '../db-utils';
import { SearchEngineService } from '../search-engine/search-engine.service';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "../loggly-logger/loggly-logger.service";
import * as i4 from "../config/config.service";
import * as i5 from "../shared/shared.service";
import * as i6 from "@ionic/storage";
import * as i7 from "../search-engine/search-engine.service";
export class FiltersService {
    constructor(events, afs, logglyService, configService, sharedService, storage, searchEngineService) {
        this.events = events;
        this.afs = afs;
        this.logglyService = logglyService;
        this.configService = configService;
        this.sharedService = sharedService;
        this.storage = storage;
        this.searchEngineService = searchEngineService;
        this.productsData = [];
        this.ALGOLIA_APP_ID = this.configService.environment.ALGOLIA_APP_ID;
        this.ALGOLIA_APP_KEY = this.configService.environment.ALGOLIA_APP_KEY;
        this.APP_PROJECT_ID = environment.firebase.projectId;
        this.allFilters = '';
        this.callCount = 0;
        this.filtersRef = this.afs.collection('features').doc('filters').collection('list');
    }
    initializeSubscriptions() {
        this.events.subscribe('filters:sortByAttribute', (id, type, attribute, sortingOrder) => {
            this.sortByAttribute(id, type, attribute, sortingOrder);
        });
        this.events.subscribe('filters:sortLoadMoreProducts', (id, type, attribute, sortingOrder) => {
            this.sortLoadMoreProducts(id, type, attribute, sortingOrder);
        });
        this.events.subscribe('filters:filterByAttributes', (data, id, type, page) => {
            this.filterByAttributes(data, id, type, page);
        });
        // admin
        this.events.subscribe('filters:saveFilter', (filterData) => {
            this.saveFilter(filterData);
        });
        this.events.subscribe('filters:toggleFiltersActive', (status) => {
            this.toggleFiltersActive(status);
        });
        this.events.subscribe('filters:getActiveStatus', () => {
            this.getActiveStatus();
        });
        this.events.subscribe('filters:getAllFilters', () => {
            this.getAllFilters();
        });
        this.events.subscribe('filters:toggleSingleFilterActive', (status, id) => {
            this.toggleSingleFilterActive(status, id);
        });
        this.events.subscribe('filters:deleteFilter', (id) => {
            this.deleteFilter(id);
        });
        this.events.subscribe('filters:getAllActiveFilters', () => {
            this.getAllActiveFilters();
        });
        this.events.subscribe('product:removeSusbcriptions', () => {
            if (this.productSub) {
                // console.log('in removeSusbcriptions unsubscribe');
                this.productSub.unsubscribe();
            }
        });
    }
    sortByAttribute(id, type, attribute, sortingOrder) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // console.log('in getProducts', id, type);
            this.productsData = [];
            let productRef;
            const region = yield this.sharedService.checkRegionIdForApi();
            let vendorId = region.vendorId;
            productRef = this.afs.collection('products', ref => ref
                .where(`${type}`, 'array-contains', id)
                .where('status', '==', true)
                .orderBy(attribute, sortingOrder)
                .limit(this.configService.environment.shopProductsLimit));
            if (vendorId && vendorId !== '') {
                productRef = this.afs.collection('products', ref => ref
                    .where(`${type}`, 'array-contains', id)
                    .where('status', '==', true)
                    .where('vendorId', '==', vendorId)
                    .orderBy(attribute, sortingOrder)
                    .limit(this.configService.environment.shopProductsLimit));
            }
            this.productSub = productRef.snapshotChanges()
                .subscribe((response) => {
                if (!response.length) {
                    // console.log('No Data Available');
                    this.events.publish('product:noProductAvailable');
                    return false;
                }
                this.productsData = [];
                this.lastInResponse = response[response.length - 1].payload.doc;
                for (const product of response) {
                    this.productsData.push({ id: product.payload.doc.id, data: product.payload.doc.data() });
                }
                // console.log('productsData in product service', this.productsData);
                if (this.productsData.length !== 0) {
                    // console.log('publishProducts');
                    this.events.publish('product:publishProducts', this.productsData);
                }
                else {
                    // console.log('noDataAvailable');
                    this.events.publish('product:noProductAvailable');
                }
            }, error => {
                console.dir(error);
                error['location'] = 'filters-service:sortByAttribute';
                this.logglyService.log(error);
            });
        });
    }
    sortLoadMoreProducts(id, type, attribute, sortingOrder) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // console.log('in loadMoreProducts service...', this.lastInResponse.id);
            let productRef;
            const region = yield this.sharedService.checkRegionIdForApi();
            let vendorId = region.vendorId;
            productRef = this.afs.collection('products', ref => ref
                .where(`${type}`, 'array-contains', id)
                .where('status', '==', true)
                .orderBy(attribute, sortingOrder)
                .limit(this.configService.environment.shopProductsLimit)
                .startAfter(this.lastInResponse));
            if (vendorId && vendorId !== '') {
                productRef = this.afs.collection('products', ref => ref
                    .where(`${type}`, 'array-contains', id)
                    .where('status', '==', true)
                    .where('vendorId', '==', vendorId)
                    .orderBy(attribute, sortingOrder)
                    .limit(this.configService.environment.shopProductsLimit)
                    .startAfter(this.lastInResponse));
            }
            productRef.snapshotChanges()
                .subscribe((response) => {
                if (!response.length) {
                    // console.log('No Data Available');
                    this.events.publish('product:productsLimitReached');
                    return false;
                }
                this.lastInResponse = response[response.length - 1].payload.doc;
                // console.log('response in loadmore', response);
                for (const product of response) {
                    this.productsData.push({ id: product.payload.doc.id, data: product.payload.doc.data() });
                }
                // console.log('load more products', this.productsData);
                this.events.publish('product:publishProducts', this.productsData);
            }, error => {
                error['location'] = 'filters-service:sortLoadMoreProducts';
                this.logglyService.log(error);
            });
        });
    }
    getCategoryName(categoryID) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const category = yield this.afs.collection('categories').doc(categoryID).valueChanges().pipe(first()).toPromise();
            // console.log('filter category', categoryID, category)
            return category.name || '';
        });
    }
    //algolia filter 
    // async filterByAttributes(data: any, id: string, type: string, page: number) {
    //     if (page === 0) {
    //         this.productsData = [];
    //         const region = await this.sharedService.checkRegionIdForApi();
    //         let vendorId = region.vendorId;
    //         let regionId = region.regionId;
    //         let filters = `status:true AND ${type}:"${id}" AND discountedPrice:${data.priceRange.lower} TO ${data.priceRange.upper} AND discount:${data.discountRange[0]} TO ${data.discountRange[1]}`;
    //         if (data.ratingRange[0] > 0) {
    //             filters += ` AND rating.avgRating:${data.ratingRange[0]} TO ${data.ratingRange[1]}`;
    //         }
    //         if (data.parentFilterObj.ids.length) {
    //             if (data.parentFilterObj.ids.length === 1) {
    //                 filters += ` AND ${data.parentFilterObj.type}:"${data.parentFilterObj.ids[0]}"`;
    //             } else {
    //                 let idFilter = '';
    //                 data.parentFilterObj.ids.map((id, index) => {
    //                     if (index === 0) {
    //                         idFilter += ` (${data.parentFilterObj.type}:"${id}"`;
    //                     } else if (index > 0 && index !== data.parentFilterObj.ids.length - 1) {
    //                         idFilter += ` OR ${data.parentFilterObj.type}:"${id}"`;
    //                     } else {
    //                         idFilter += ` OR ${data.parentFilterObj.type}:"${id}")`;
    //                     }
    //                 });
    //                 filters += ` AND ${idFilter}`;
    //             }
    //         }
    //         if (regionId) {
    //             filters += ` AND (categoryRegions:${regionId} OR brandRegions:${regionId})`
    //         }
    //         if (vendorId) {
    //             filters += ` AND vendorId:${vendorId}`
    //         }
    //         if (data.adminFilters.length) {
    //             let adminFilters = {};
    //             data.adminFilters.forEach(filter => {
    //                 filter.values.forEach(v => {
    //                     if (v.isChecked) {
    //                         if (adminFilters.hasOwnProperty(filter.name)) {
    //                             let valArr = adminFilters[filter.name];
    //                             valArr.push(v.value);
    //                             adminFilters[filter.name] = valArr;
    //                         } else {
    //                             adminFilters[filter.name] = [v.value];
    //                         }
    //                     }
    //                 });
    //             });
    //             let keys = Object.keys(adminFilters);
    //             if (keys.length > 0) {
    //                 keys.forEach(key => {
    //                     let values = adminFilters[key];
    //                     let valueFilter = ' AND';
    //                     values.map((value, index) => {
    //                         if (values.length === 1) {
    //                             valueFilter += ` (filters.${key}:"${value}")`
    //                         } else {
    //                             if (index === 0) {
    //                                 valueFilter += ` (filters.${key}:"${value}"`;
    //                             } else if (index > 0 && index !== values.length - 1) {
    //                                 valueFilter += ` OR filters.${key}:"${value}"`;
    //                             } else {
    //                                 valueFilter += ` OR filters.${key}:"${value}")`;
    //                             }
    //                         }
    //                     });
    //                     filters += valueFilter;
    //                 });
    //             }
    //         }
    //         this.allFilters = filters;
    //     }
    //     // console.log('allFilters', this.allFilters);
    //     this.client = algoliasearch(this.ALGOLIA_APP_ID, this.ALGOLIA_APP_KEY);
    //     this.index = this.client.initIndex(this.APP_PROJECT_ID);
    //     this.index.search('', { page: page, filters: this.allFilters }).then((result) => {
    //         // console.log(result);
    //         if (result.nbPages === 0) {
    //             this.events.publish('product:noProductAvailable');
    //         } else if (result.hits.length === 0 && page === result.nbPages) {
    //             this.events.publish('product:productsLimitReached');
    //         } else {
    //             result.hits.forEach(h => {
    //                 this.productsData.push({ id: h.objectID, data: h });
    //             });
    //             console.log('this.productsData',this.productsData)
    //             this.events.publish('product:publishProducts', this.productsData);
    //         }
    //     }).catch(async (error) => {
    //         console.dir(error);
    //         error['location'] = 'filters-service:filterByAttributes';
    //         this.logglyService.log(error);
    //     });
    // }
    //typesense filter
    filterByAttributes(data, id, type, page) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // page += 1;
            if (page === 1) {
                this.productsData = [];
                // const region = await this.sharedService.checkRegionIdForApi();
                // let vendorId = region.vendorId;
                // let regionId = region.regionId;
                let filters = `status:true && ${type}:=${id} && discountedPrice:=[${data.priceRange.lower}..${data.priceRange.upper}] && discount:=[${data.discountRange[0]}..${data.discountRange[1]}]`;
                if (data.ratingRange[0] > 0) {
                    filters += ` && rating.avgRating:=[${data.ratingRange[0]}..${data.ratingRange[1]}]`;
                }
                // if (vendorId) {
                //     filters += ` && vendorId:=${vendorId}`;
                // }
                if (data.parentFilterObj.ids.length) {
                    filters += ` && ${data.parentFilterObj.type}:=[${data.parentFilterObj.ids}]`;
                }
                // if (regionId) {
                //     filters += ` AND (categoryRegions:${regionId} OR brandRegions:${regionId})`;
                // }
                if (data.adminFilters.length) {
                    let adminFilters = {};
                    data.adminFilters.forEach(filter => {
                        filter.values.forEach(v => {
                            if (v.isChecked) {
                                if (adminFilters.hasOwnProperty(filter.name)) {
                                    let valArr = adminFilters[filter.name];
                                    valArr.push(v.value);
                                    adminFilters[filter.name] = valArr;
                                }
                                else {
                                    adminFilters[filter.name] = [v.value];
                                }
                            }
                        });
                    });
                    let keys = Object.keys(adminFilters);
                    if (keys.length > 0) {
                        keys.forEach(key => {
                            let values = adminFilters[key];
                            let valueFilter = ' &&';
                            values.map((value, index) => {
                                if (index === 0) {
                                    valueFilter += ` filters.${key}:="${value}"`;
                                }
                                else if (index > 0 && index !== values.length - 1) {
                                    valueFilter += ` && filters.${key}:="${value}"`;
                                }
                                else {
                                    valueFilter += ` && filters.${key}:="${value}"`;
                                }
                            });
                            filters += valueFilter;
                        });
                    }
                }
                this.allFilters = filters;
            }
            console.log('allFilters', this.allFilters);
            if (this.configService.environment.useTypesense) {
                const res = yield this.searchEngineService.getSearchProductsFromTypesense('*', page, type, this.allFilters);
                if (res.status === 'available') {
                    res.products.forEach(product => {
                        product.data = product;
                        this.productsData.push(product);
                    });
                    this.events.publish('product:publishProducts', this.productsData);
                }
                else if (res.status === 'no_products') {
                    this.events.publish('product:noProductAvailable');
                }
                else {
                    this.events.publish('product:productsLimitReached');
                }
            }
            // this.client = algoliasearch(this.ALGOLIA_APP_ID, this.ALGOLIA_APP_KEY);
            // this.index = this.client.initIndex(this.APP_PROJECT_ID);
            // this.index.search('', { page: page, filters: this.allFilters }).then((result) => {
            //     // console.log(result);
            //     if (result.nbPages === 0) {
            //         this.events.publish('product:noProductAvailable');
            //     } else if (result.hits.length === 0 && page === result.nbPages) {
            //         this.events.publish('product:productsLimitReached');
            //     } else {
            //         result.hits.forEach(h => {
            //             this.productsData.push({ id: h.objectID, data: h });
            //         });
            //         console.log('this.productsData',this.productsData)
            //         this.events.publish('product:publishProducts', this.productsData);
            //     }
            // }).catch(async (error) => {
            //     console.dir(error);
            //     error['location'] = 'filters-service:filterByAttributes';
            //     this.logglyService.log(error);
            // });
        });
    }
    saveFilter(filterData) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let filterId = '';
                let filterClone = JSON.parse(JSON.stringify(filterData));
                if (filterClone.hasOwnProperty('id')) {
                    filterId = filterClone.id;
                    delete filterData.id;
                }
                else {
                    filterId = this.filtersRef.ref.doc().id;
                }
                yield this.filtersRef.doc(filterId).set(filterData);
                this.events.publish('filters:filterSaved');
                this.events.publish('filters:getAllFilters');
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'filters-service:saveFilter';
                this.logglyService.log(error);
            }
        });
    }
    toggleFiltersActive(status) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.afs.collection('features').doc('filters').set({ active: status });
                this.events.publish('filters:filtersActiveChanged');
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'filters-service:toggleFiltersActive';
                this.logglyService.log(error);
            }
        });
    }
    getActiveStatus(route) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const filtersDoc = yield this.afs.collection('features').doc('filters').valueChanges().pipe(first()).toPromise();
                if (route === 'service') {
                    return filtersDoc;
                }
                else {
                    this.events.publish('filters:publishActiveStatus', filtersDoc);
                }
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'filters-service:getActiveStatus';
                this.logglyService.log(error);
            }
        });
    }
    getAllFilters() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const filters = yield this.filtersRef.snapshotChanges().pipe(map(actions => actions.map(a => {
                    const data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return Object.assign({ id }, data);
                }))).pipe(first()).toPromise();
                // console.log('filters', filters);
                this.events.publish('filters:publishAllFilters', filters);
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'filters-service:getAllFilters';
                this.logglyService.log(error);
            }
        });
    }
    toggleSingleFilterActive(status, id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.filtersRef.doc(id).update({ active: status });
                this.events.publish('filters:singleFilterActiveChanged');
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'filters-service:toggleSingleFilterActive';
                this.logglyService.log(error);
            }
        });
    }
    deleteFilter(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.filtersRef.doc(id).delete();
                this.events.publish('filters:filterDeleted');
                this.events.publish('filters:getAllFilters');
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'filters-service:deleteFilter';
                this.logglyService.log(error);
            }
        });
    }
    getAllActiveFilters() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const filters = yield this.afs.collection('features').doc('filters').collection('list', ref => ref
                    .where('active', '==', true)).snapshotChanges().pipe(map(actions => actions.map(a => {
                    const data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return Object.assign({ id }, data);
                }))).pipe(first()).toPromise();
                // console.log('filters', filters);
                return filters;
                // this.events.publish('filters:publishAllActiveFilters', filters);
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'filters-service:getAllActiveFilters';
                this.logglyService.log(error);
            }
        });
    }
    getCategoriesWithSubcategories() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let list = [];
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                const categories = yield this.afs.collection('categories', ref => ref
                    .orderBy('sortedAt', 'desc')
                    .where('status', '==', true)).snapshotChanges().pipe(map(snaps => convertSnaps(snaps))).pipe(first()).toPromise();
                for (const c of categories) {
                    if (c.isSubcategories) {
                        const subcategories = yield this.getSubcategories(c.id);
                        let sublist = [];
                        if (subcategories.length) {
                            for (const sc of subcategories) {
                                sublist.push({ id: sc.id, name: sc.name, active: false });
                            }
                        }
                        list.push({ id: c.id, name: c.name, sublist, active: false });
                    }
                    else {
                        list.push({ id: c.id, name: c.name, sublist: [], active: false });
                    }
                }
                resolve(list);
            }));
        });
    }
    getSubcategories(cid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                const subcategories = yield this.afs.collection('categories').doc(cid).collection('subcategories', ref => ref.orderBy('sortedAt', 'desc').where('status', '==', true)).snapshotChanges().pipe(map(snaps => convertSnaps(snaps))).pipe(first()).toPromise();
                resolve(subcategories);
            }));
        });
    }
    getBrands() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let list = [];
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                const brands = yield this.afs.collection('brands', ref => ref
                    .orderBy('sortedAt', 'desc')
                    .where('status', '==', true)).snapshotChanges().pipe(map(snaps => convertSnaps(snaps))).pipe(first()).toPromise();
                for (const b of brands) {
                    list.push({ id: b.id, name: b.name, sublist: [], active: false });
                }
                resolve(list);
            }));
        });
    }
}
FiltersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FiltersService_Factory() { return new FiltersService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.LogglyLoggerService), i0.ɵɵinject(i4.ConfigService), i0.ɵɵinject(i5.SharedService), i0.ɵɵinject(i6.Storage), i0.ɵɵinject(i7.SearchEngineService)); }, token: FiltersService, providedIn: "root" });
